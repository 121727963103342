import React, { useCallback, useState } from "react";
import Button from "../UI/Button/Button";
import Input from "../UI/Input/Input";
import classes from "./Login.module.css";

const Login = (props) => {
  const [hasAccount, setHasAccount] = useState(true);

  const toggleSignInSignUp = useCallback(() => {
    console.log(1);
    setHasAccount((hasAccount) => !hasAccount);
  }, []);

  return (
    <React.Fragment>
      <div class={classes["login-card-wrapper"]}>
        <div class={classes["login-card"]}>
          <h1>Hello!</h1>
          <h3>Welcome to Todi.</h3>
          <form>
            {hasAccount === true && (
              <div>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email address"
                  type="email"
                />
                <Input
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                />
                <div>
                  <Button className="default" type="submit">
                    Sign-in
                  </Button>
                </div>
                <p>
                  Don't have an account yet?{" "}
                  <Button onClick={toggleSignInSignUp}>Sign-up here.</Button>
                </p>
              </div>
            )}

            {!hasAccount && (
              <div>
                <Input
                  id="email"
                  name="email"
                  placeholder="Email address"
                  type="email"
                />
                <Input
                  id="password"
                  name="password"
                  placeholder="Password"
                  type="password"
                />
                <Input
                  id="confirm-password"
                  name="confirmPassword"
                  placeholder="Confirm password"
                  type="password"
                />
                <div>
                  <Button className="signup" type="submit">
                    Sign-up
                  </Button>
                </div>
                <p>
                  Already have an account?{" "}
                  <Button onClick={toggleSignInSignUp}>Sign-in here.</Button>
                </p>
              </div>
            )}
          </form>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
